import { useEffect } from "react";
import { createPortal } from "react-dom";

const TheaterPortal = ({children}) => {
  const mount = typeof document !== `undefined` ? document.getElementById("theater-root") : null
  const el = typeof document !== `undefined` ? document.createElement("div") : null

  useEffect(() => {
    const theaterStyles = document.getElementById("theater-styles")
    
    const menuKeyframes = generateKeyframesStyle(
      window.innerWidth,
      window.innerHeight
    )

    if (!theaterStyles) {
      const $style = document.createElement("style")
      $style.setAttribute("id", "theater-styles")
      $style.innerHTML = menuKeyframes
      document.head.appendChild($style)
    }
    
    
  }, [])

  useEffect(() => {
    
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  if (el) {
    return createPortal(children, el)
  } else {
    return null
  }

};

export default TheaterPortal;

function generateKeyframesStyle(width, height, modifier) {
  let radius = 5000
  if (width > height) {
    radius = width
  } else if (width < height) {
    radius = height
  } else {
    radius = width
  }

  // 2 is too low
  if (!modifier) {
    modifier = 3
  }

  radius = radius * modifier

  return `
    @keyframes menuAnimOpenBottomLeft {
      0% {
        top: unset;
        bottom: ${-0.5 * radius}px;
        left: ${-0.5 * radius}px;
        width: 0;
        height: 0;
        border-radius: 50%;
      }
      100% {
        top: unset;
        bottom: ${-0.5 * radius}px;
        left: ${-0.5 * radius}px;
        width: ${radius}px;
        height: ${radius}px;
        border-radius: 50%;
      }
    }
    @keyframes menuAnimCloseBottomLeft {
      0% {
        top: unset;
        bottom: ${-0.5 * radius}px;
        left: ${-0.5 * radius}px;
        width: ${radius}px;
        height: ${radius}px;
        border-radius: 50%;
      }
      100% {
        top: unset;
        bottom: ${-0.5 * radius}px;
        left: ${-0.5 * radius}px;
        width: 0;
        height: 0;
        border-radius: 50%;
      }
    }`
}