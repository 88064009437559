import PropTypes from "prop-types"
import React from "react"

import VideoButton from "./VideoButton"
import Overlay from "./Overlay"

class TheaterVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: "two-lines",
      position: "right",
      isOpen: false,
      isOpening: false,
      isClosing: false,
    }
    this.toggleTheater = this.toggleTheater.bind(this)
    this.escFunction = this.escFunction.bind(this)
  }

  componentDidMount() {
    var menu = document.getElementById("mainNav")
    var pause = document.getElementById("sizzlePause")
    this.menu = menu
    this.pauseButton = pause

    document.addEventListener("keydown", this.escFunction, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.isOpen) {
      this.toggleTheater()
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log(this.state);
  // }

  toggleTheater() {
    //todo
    if (this.state.isOpen) {
      this.props.resetSlider ? this.props.resetSlider() : null;
      if (this.pauseButton) {
        setTimeout(() => {
          this.pauseButton.style.display = "block"
        }, 450)
      }

      this.setState(
        prevState => ({
          ...prevState,
          isClosing: !prevState.isClosing,
          playing: false
        }),
        function () {
          setTimeout(() => {
            this.setState(prevState => ({
              ...prevState,
              isOpen: !prevState.isOpen,
              isClosing: !prevState.isClosing,
            }),)
            this.menu.style.zIndex = "100"
          }, 450)
        }
      )
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    } else if (!this.state.isOpen) {
      this.props.pauseSlider ? this.props.pauseSlider() : null;
      this.menu.style.zIndex = "0"
      if (this.pauseButton) {
        this.pauseButton.style.display = "none"
      }
      this.setState({
        isOpen: true,
        isOpening: true,
      }, () => {
        setTimeout(() => {
        this.setState({
          isOpening: false,
        }, () => {
          this.setState({playing: true});
        })
      }, 450)
      })
      
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.isOpening !== nextState.isOpening) {
      return false;
    }
    return true;
  }
  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { onClick: this.toggleTheater })
    )

    var hasWatchVideo = true
    var onlyButton = false
    var overVideo = false
    var controls = true
    var playing = true
    var sizzleButton = false

    if (this.props.hasWatchVideo === false) {
      hasWatchVideo = false
    }
    if (this.props.onlyButton === true) {
      onlyButton = true
    } else {
      if (this.props.overVideo === true) {
        overVideo = true
      }
    }
    if (this.props.controls === false) {
      controls = false
    }
    if (this.props.playing === false) {
      playing = false
    }
    if (this.props.sizzleButton === true) {
      sizzleButton = true
    }

    return (
      <>
        <Overlay
          toggleTheater={this.toggleTheater}
          isOpen={this.state.isOpen}
          isClosing={this.state.isClosing}
          isOpening={this.state.isOpening}
          videoUrl={this.props.videoUrl}
          controls={controls}
          playing={playing}
          className={this.props.containerClass}

        //ext={this.extControl}
        />
        {hasWatchVideo && !onlyButton && (
          <div className="image-button-wrapper" onClick={this.toggleTheater}>
            <VideoButton
              buttonText={this.props.buttonText}
              textOnly={this.props.textOnly}
              language={this.props.language}
              toggleTheater={this.toggleTheater}
              overVideo={overVideo}
              iconOnly={this.props.iconOnly}
              buttonClass={this.props.buttonClass}
            />
            {this.props.children}
          </div>
        )}
        {onlyButton && sizzleButton && (
          <div className="button-group is-centered-mobile" style={{width: "100%"}}>
            <VideoButton
              buttonText={this.props.buttonText}
              textOnly={this.props.textOnly}
              language={this.props.language}
              toggleTheater={this.toggleTheater}
              overVideo={overVideo}
              iconOnly={this.props.iconOnly}
              sizzleButton={sizzleButton}
              buttonClass={this.props.buttonClass}
            />
          </div>
        )}
        {onlyButton && !sizzleButton && this.props.slideButton && (
          <div className="slideVidButton">
            <VideoButton
              buttonText={this.props.buttonText}
              textOnly={this.props.textOnly}
              language={this.props.language}
              toggleTheater={this.toggleTheater}
              overVideo={overVideo}
              iconOnly={this.props.iconOnly}
              buttonClass={this.props.buttonClass}
            />
          </div>
        )}
        {/* TODO */}
        {onlyButton && !sizzleButton && !this.props.slideButton && this.props.falseButton && (
            <VideoButton
              buttonText={this.props.buttonText}
              textOnly={this.props.textOnly}
              language={this.props.language}
              toggleTheater={this.toggleTheater}
              overVideo={overVideo}
              iconOnly={this.props.iconOnly}
              buttonClass={this.props.buttonClass}
              falseButton
            />
        )}
        {onlyButton && !sizzleButton && !this.props.slideButton && !this.props.falseButton && (
          <VideoButton
            buttonText={this.props.buttonText}
            textOnly={this.props.textOnly}
            language={this.props.language}
            toggleTheater={this.toggleTheater}
            overVideo={overVideo}
            iconOnly={this.props.iconOnly}
            buttonClass={this.props.buttonClass}
            isAnimated={this.props.isAnimated}
          />
        )}
        {!hasWatchVideo && childrenWithProps}
      </>
    )
  }
}

TheaterVideo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  videoUrl: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  playing: PropTypes.bool,
  overVideo: PropTypes.bool,
  hasWatchVideo: PropTypes.bool,
  onlyButton: PropTypes.bool,
  sizzleButton: PropTypes.bool
}

export default TheaterVideo
