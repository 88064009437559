import PropTypes from "prop-types"
import React from "react"

import Video from "./Video"
import HamburgerIconTheater from "./HamburgerIconTheater.js"
import TheaterPortal from "./TheaterPortal"

var classNames = require("classnames")

class Overlay extends React.Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidUpdate() {
    if (this.props.isClosing) {
      document.documentElement.classList.remove("theater-is-open")
    } else if (this.props.isOpen) {
      document.documentElement.classList.add("theater-is-open")
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  // Generates keyframe style tag to be document.head.appendChild'ed;
  generateKeyframesStyle(width, height, modifier) {
    let radius = 5000
    if (width > height) {
      radius = width
    } else if (width < height) {
      radius = height
    } else {
      radius = width
    }

    // 2 is too low
    if (!modifier) {
      modifier = 3
    }

    radius = radius * modifier

    return `
      @keyframes menuAnimOpenBottomLeft {
        0% {
          top: unset;
          bottom: ${-0.5 * radius}px;
          left: ${-0.5 * radius}px;
          width: 0;
          height: 0;
          border-radius: 50%;
        }
        100% {
          top: unset;
          bottom: ${-0.5 * radius}px;
          left: ${-0.5 * radius}px;
          width: ${radius}px;
          height: ${radius}px;
          border-radius: 50%;
        }
      }
      @keyframes menuAnimCloseBottomLeft {
        0% {
          top: unset;
          bottom: ${-0.5 * radius}px;
          left: ${-0.5 * radius}px;
          width: ${radius}px;
          height: ${radius}px;
          border-radius: 50%;
        }
        100% {
          top: unset;
          bottom: ${-0.5 * radius}px;
          left: ${-0.5 * radius}px;
          width: 0;
          height: 0;
          border-radius: 50%;
        }
      }`
  }

  // componentDidMount() {
  //   this.updateWindowDimensions()
  //   window.addEventListener("resize", this.updateWindowDimensions)
  //   const menuKeyframes = this.generateKeyframesStyle(
  //     window.innerWidth,
  //     window.innerHeight
  //   )
  //   // TO-DO: Check if animation style already exists from a previous video component, if so, do not add another identical style tag to document head
  //   const $style = document.createElement("style")
  //   $style.innerHTML = menuKeyframes
  //   document.head.appendChild($style)
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateWindowDimensions)
  // }

  render() {
    let iconColor = "#6d6e6f"
    let iconClass = classNames({
      "theater-icon": true,
      open: this.props.isOpen,
    })
    //   if (this.props.atTopOfPage) {
    //   iconColor = "white"
    // }

    let containerClasses = classNames(this.props.className, {
      "theater-container": true
    })

    return (
      <TheaterPortal>
        <div className={containerClasses}>
          <Video
            toggleTheater={this.props.toggleTheater}
            isOpen={this.props.isOpen}
            isClosing={this.props.isClosing}
            isOpening={this.props.isOpening}
            key={this.props.compId}
            videoUrl={this.props.videoUrl}
            controls={this.props.controls}
            playing={this.props.playing}
            compId={this.props.compId}
          />
          {this.props.isOpen && (
            <HamburgerIconTheater
              toggleTheater={this.props.toggleTheater}
              className={iconClass}
              color={iconColor}
              isOpen={this.props.isOpen}
              isClosing={this.props.isClosing}
              isOpening={this.props.isOpening}
              ext={this.props.ext}
              compId={this.props.compId}
            />
          )}
        </div>
      </TheaterPortal>
    )
  }
}
Overlay.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  toggleTheater: PropTypes.func,
  videoUrl: PropTypes.string,
  controls: PropTypes.bool,
  playing: PropTypes.bool,
}

export default Overlay
